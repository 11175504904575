import React from "react";

const EmailsToLinks: React.FC<{ text: string }> = ({ text }) => {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  const parts: string[] = text.split(emailRegex);
  const emails: string[] = text.match(emailRegex) || [];

  return parts.reduce<React.ReactNode[]>((acc, part, index) => {
    acc.push(part);
    if (index < emails.length) {
      acc.push(
        <a
          key={index}
          href={`mailto:${emails[index]}`}
          target="_blank"
          rel="noreferrer"
        >
          {emails[index]}
        </a>,
      );
    }
    return acc;
  }, []);
};

export default EmailsToLinks;
